<template>
  <MainPage :title="$t('lang.msg11')" :nomore="true">
    <div slot="content" >
        <div class="main-index main-bg2">
            <!-- <img class="main-banner" src="@/assets/home-banner.jpg"> -->
            <cube-scroll  ref="scroll" >
            <div style="margin: 20px 20px;padding: 40px 25px;background-color: #222222d6;">
                <form>
                    <div style="margin-top: 15px;">
                        <cube-input v-model="form_reg.username"
                            :placeholder="$t('lang.msg3')"
                            type="text"
                            :clearable="{visible: true, blurHidden: true}" class="reg-field"
                            autocomplete="false"
                            >
                            <i slot="prepend" class="cubeic-person reg-icon"></i>
                        </cube-input>
                        <cube-validator v-model="check0" :model="form_reg.username" :rules="rules0" class="error-msg"></cube-validator>
                    </div>
                    <div style="margin-top: 10px;">
                        <cube-input v-model="form_reg.pwd"
                            :placeholder="$t('lang.msg195')"
                            type="password"
                            :eye="{open: false,reverse: false}"
                            class="reg-field"
                            autocomplete="false"
                            >
                            <i slot="prepend" class="cubeic-lock reg-icon"></i>
                        </cube-input>
                        <cube-validator v-model="check1" :model="form_reg.pwd" :rules="rules1" class="error-msg"></cube-validator>
                    </div>
                    <cube-checkbox v-model="checked" class="reg-checkbox" >
                    {{$t('lang.msg8')}}
                    </cube-checkbox>
                    <div class="padding">
                        <cube-button  @click="login" class="reg-button green-btn" :disabled="!check0 || !check1 " :class="{'disabled-btn':!check0 || !check1 }">{{$t('lang.msg9')}}</cube-button>
                    </div>
                    <div class="padding">
                        <cube-button  @click="service()" class="reg-button green-btn" >{{$t('lang.msg208')}}</cube-button>
                    </div>
    <!--                <div class="padding">
                        <cube-button  @click="go2page('/reg')" class="reg-button blue-btn" >{{$t('lang.msg10')}}</cube-button>
                    </div> -->
                </form>
            </div>
            </cube-scroll>
        </div>
    </div>
  </MainPage>
</template>

<script type="text/ecmascript-6" >
import MainPage from '@/components/nav-bar.vue'
import { validator } from '@/utils/validator.js'
import { mapGetters } from "vuex";
import { apiLogin } from "@/api.js";
import { globalMixin } from '@/mixin.js';
import { Locale } from 'cube-ui'
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            checked:this.$cookies.get('username')?true:false,
            check0:this.$cookies.get('username')?true:false,
            check1:false,
            MyIP:"",
            form_reg:{
                username: this.$cookies.get('username')?this.$cookies.get('username'):'',
                pwd: '',
            },
            rules0:{
                type: 'string',
                required: true,
                pattern: validator.username,
            },
            rules1:{
                type: 'string',
                required: true,
                pattern: validator.password,
                min:6,
            }
        }
    },
    methods: {
        back()
        {
          this.$router.back()
        },
        login(){
            if(!validator.isUsername(this.form_reg.username)){
                this.toast(this.$t('lang.err2'));
                return;
            }
            if(this.checked)
            {
                this.$cookies.set('username', this.form_reg.username, '30d');
            }else
            {
                this.$cookies.remove('username');
            }
            this.toast('Loading...',null,0,'loading');
            apiLogin({
                Username:this.form_reg.username,
                Password:this.form_reg.pwd,
                //LoginIP : localStorage.getItem('Ip'),
                LoginIP : this.config.MyIP,
            })
            .then(response => {
                this.$createToast().hide();
                if(!response.data.status)
                {
                    this.error_handler(response.data);
                }
                if(response.data.status)
                {
                    // setTimeout(()=> {
                    //     this.$store.dispatch('getCoin');
                    // }, 15000);
                    this.$store.dispatch('setUserInfo',response.data.data);
                    this.$router.push("/game");
                }
                return;
            }).catch(error => {
                console.log(error);
                return;
            });
        }
    },
    computed: {
		...mapGetters({
            user: "user",
            config: "config",
        }),
    },
    components: {
        MainPage,
    },
    watch:{
        'user.Username':function(){
            if(this.user.Username!='' || this.user.Username){
                this.$router.push("/game");
            }
        }
    },
    created() {
        this.checkLoginNoMsg();
    }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>
