
const validator = {
    username: /[0-9A-Za-z]{4,12}$/,
    password: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/,
    //phone: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/, // 大陸
    phone: /(^09\d{8}$)/,  // 臺灣
    chinese: /^[\u4E00-\u9FA5]+$/,
    email: /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/,
    pos_int: /^\d+$/,	//
    reg_float: /^-?\d*(\.\d+)?$/,	// 小數
    reg_float_1_digit: /^[0-9]{1,}(\.)?\d{0,1}$/,	// 小數點一位
    reg_float_2_digit: /^[0-9]{1,}(\.)?\d{0,2}$/,	// 小數點兩位
    num_float: function (num) {
        return this.reg_float.test(num);
    },
    num_float_1_digit: function (num) {
        return this.reg_float_1_digit.test(num);
    },
    num_float_2_digit: function (num) {
        return this.reg_float_2_digit.test(num);
    },
    num_pos_int: function (num) {
        return this.pos_int.test(num);
    },
    isUsername: function (str) {
        return this.username.test(str);
    },
    isPassword: function (str) {
        return this.password.test(str);
    },
    isPhone: function (str) {
        return this.phone.test(str);
    },
    isEmail: function (str) {
        return this.email.test(str);
    },
    isChinese: function (str) {
        return this.chinese.test(str);
    }
};

export { validator }

